import AddUser from '../components/AddUser'
import UserList from '../components/UserList'

export default function Users() {
  return (
    <div className='container'>
      <h1 className='mb-2'>User Management</h1>
      <div className='row'>
        <div className='col-4'>
          <h4>Add User</h4>
          <AddUser />
        </div>
        <div className='col-8'>
          <h4>User List</h4>
          <UserList />
        </div>
      </div>
    </div>
  )
}
