import { useForm } from 'react-hook-form'
import axios from 'axios'
import getUrl from '../hooks/getUrl'

export default function AddUser() {
  const { register, handleSubmit } = useForm()

  const onSubmit = async (values) => {
    // eslint-disable-next-line
    const res = await axios.post(`${getUrl()}/api/users/register`, values)
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className='form-group'>
        <input
          name='name'
          type='text'
          placeholder='Name...'
          className='form-control'
          ref={register}
        />
      </div>
      <div className='form-group'>
        <input
          name='email'
          type='email'
          placeholder='Email...'
          className='form-control'
          ref={register}
        />
      </div>
      <div className='form-group'>
        <input
          name='role'
          type='role'
          placeholder='Role...'
          className='form-control'
          ref={register}
        />
      </div>
      <div className='form-group'>
        <button className='btn btn-primary'>Register User</button>
      </div>
    </form>
  )
}
