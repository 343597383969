export default function UserCard({ name, email, role }) {
  return (
    <div className='card' style={{ marginBottom: '10px' }}>
      <div className='card-body'>
        <h5 className='card-title'>{name}</h5>
        <h6 className='card-subtitle mb-2 text-muted'>{email}</h6>
        <p>
          <strong>Role:</strong> {role}
        </p>
      </div>
    </div>
  )
}
