import client from '../client'
import groq from 'groq'
import { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'

export default function Docs() {
  const [docs, setDocs] = useState()

  useEffect(() => {
    async function getDocs() {
      let res = await client.fetch(groq`*[_type == 'post'] {
        title, 
        slug
      }`)
      setDocs(res)
    }
    getDocs()
  }, [])

  return (
    <div className='container'>
      <h1>Docs</h1>
      <ul>
        {docs &&
          docs.map((doc) => (
            <li>
              <Link to={`/docs/${doc.slug.current}`}>{doc.title}</Link>
            </li>
          ))}
      </ul>
    </div>
  )
}
