import { Redirect, Route } from 'react-router-dom'
import { useContext } from 'react'
import { UserContext } from '../context/userContext'

export default function PrivateRoute({ component: Component, ...rest }) {
  const { state } = useContext(UserContext)

  return (
    <Route
      {...rest}
      render={(props) =>
        state.user ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{ pathname: '/login', state: { from: props.location } }}
          />
        )
      }
    />
  )
}
