import { createContext, useReducer } from 'react'
import * as Cookie from 'js-cookie'

const UserContext = createContext()

const sessionCookie = Cookie.get('session')

let initialState = {
  user: sessionCookie !== undefined ? JSON.parse(sessionCookie) : null,
}

let reducer = (state, action) => {
  switch (action.type) {
    case 'login':
      return { ...state, user: action.payload }
    case 'logout':
      return { ...state, user: null }
    default:
      return { user: null }
  }
}

function UserContextProvider({ children }) {
  let [state, dispatch] = useReducer(reducer, initialState)
  let value = { state, dispatch }
  return <UserContext.Provider value={value}>{children}</UserContext.Provider>
}

let UserContextConsumer = UserContext.Consumer

export { UserContext, UserContextProvider, UserContextConsumer }
