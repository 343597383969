import { Route, Switch } from 'react-router-dom'
import PrivateRoute from './components/PrivateRoute'
import { useContext } from 'react'
import { UserContext } from './context/userContext'

// Components
import Home from './pages/Home'
import Docs from './pages/Docs'
import Nav from './components/Nav'
import Login from './pages/Login'
import Import from './pages/Import'
import AdminTools from './components/AdminTools'
import Users from './pages/Users'
import Activate from './pages/Activate'
import Doc from './pages/Doc'

export default function Routes() {
  const { state } = useContext(UserContext)

  return (
    <>
      {state.user && (
        <>
          <Nav />
          <AdminTools />
        </>
      )}
      <Switch>
        <PrivateRoute path='/import' component={Import} />
        <PrivateRoute path='/docs/:id' component={Doc} />
        <PrivateRoute exact path='/docs' component={Docs} />
        <PrivateRoute path='/add-user' component={Users} />
        <Route path='/activate' component={Activate} />
        <Route path='/login' component={Login} />
        <PrivateRoute path='/' component={Home} />
      </Switch>
    </>
  )
}
