import * as QueryString from 'query-string'
import { useHistory } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import { useState } from 'react'
import getUrl from '../hooks/getUrl'

export default function Activate({ location }) {
  const email = QueryString.parse(location.search).e
  const { register, handleSubmit } = useForm()
  const [errors, setErrors] = useState()
  const history = useHistory()

  const onSubmit = async ({ password }) => {
    const body = {
      email,
      password,
    }
    console.log(body)
    const res = await fetch(`${getUrl()}/api/users/activate`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(body),
    })
    const json = await res.json()

    if (!json) {
      return setErrors('An error has occured')
    }

    history.push('/login')
  }
  return (
    <div
      className='background'
      style={{
        backgroundColor: '#343a40',
        height: '100vh',
        width: '100vw',
        display: 'flex',
        alignItems: 'center',
      }}
    >
      <div
        className='container'
        style={{
          backgroundColor: '#fff',
          padding: '30px',
          borderRadius: '10px',
          width: '600px',
          marginTop: '-200px',
        }}
      >
        <h3>Hi {email},</h3>
        <p>Please activate your account</p>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className='form-group'>
            <input
              className='form-control'
              name='password'
              type='password'
              placeholder='Password...'
              ref={register}
            />
          </div>
          <button type='submit' className='btn btn-primary'>
            Activate
          </button>
        </form>
        {errors && errors}
      </div>
    </div>
  )
}
