// import { useEffect, useState } from 'react'
// import getUrl from '../hooks/getUrl'

export default function Dailyusers() {
  // const [isLoading, setIsLoading] = useState(true)
  // const [signups, setSignups] = useState({})

  // useEffect(() => {
  //   async function getSignupsToday() {
  //     const res = await fetch(`${getUrl()}/api/supporters/daily-signups`)
  //     const json = await res.json()
  //     setSignups(json)
  //     setIsLoading(false)
  //   }
  //   getSignupsToday()
  // }, [])

  return <p>number</p>
}
