import { useState } from 'react'
import { parse } from 'papaparse'

export default function Import() {
  const [file, setFile] = useState()
  const [uploading, setUploading] = useState(false)

  const handleSubmit = (e) => {
    e.preventDefault()
    setUploading(true)

    parse(file, {
      header: true,
      complete: function (data) {
        setUploading(false)
        setFile(data)
      },
    })
  }
  return (
    <div className='container'>
      <h1>Import</h1>
      {uploading && <p>Uploading</p>}
      <form onSubmit={handleSubmit}>
        <div className='form-group'>
          <input
            name='csv'
            type='file'
            onChange={(e) => {
              setFile(e.target.files[0])
            }}
          />
        </div>
        <div className='form-group'>
          <button className='btn btn-primary'>Submit</button>
        </div>
      </form>
    </div>
  )
}
