import { useContext } from 'react'
import { UserContext } from '../context/userContext'
import { useForm } from 'react-hook-form'
import { useHistory } from 'react-router-dom'
import { setSessionCookie } from '../session'
import axios from 'axios'
import getUrl from '../hooks/getUrl'

export default function Login() {
  const { handleSubmit, register } = useForm()
  let { state, dispatch } = useContext(UserContext)
  const history = useHistory()

  const onSubmit = async (values) => {
    const res = await axios.post(`${getUrl()}/api/users/login`, values)
    await dispatch({ type: 'login', payload: res.data })
    setSessionCookie(res.data)
    history.push('/')
  }

  return (
    <div
      className='background'
      style={{
        backgroundColor: '#343a40',
        height: '100vh',
        width: '100vw',
        display: 'flex',
        alignItems: 'center',
      }}
    >
      <div
        className='container'
        style={{
          backgroundColor: '#fff',
          padding: '30px',
          borderRadius: '10px',
          width: '600px',
          marginTop: '-200px',
        }}
      >
        <h3>Login</h3>
        {state.user ? (
          <p>Hey</p>
        ) : (
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className='form-group'>
              <input
                className='form-control'
                name='email'
                type='email'
                placeholder='Email...'
                ref={register}
              />
            </div>
            <div className='form-group'>
              <input
                className='form-control'
                name='password'
                type='password'
                placeholder='Password...'
                ref={register}
              />
            </div>
            <button type='submit' className='btn btn-primary'>
              Submit
            </button>
          </form>
        )}
      </div>
    </div>
  )
}
