import { useState, useEffect } from 'react'
import client from '../client'
import groq from 'groq'
import BlockContent from '@sanity/block-content-to-react'

export default function Doc({ location }) {
  const [doc, setDoc] = useState()

  useEffect(() => {
    async function getDoc() {
      const slug = location.pathname.split('/docs/')[1]
      const res = await client.fetch(
        groq`
          *[_type == 'post' && slug.current == $slug]
        `,
        { slug },
      )
      setDoc(res[0])
    }
    getDoc()
  })

  if (!doc) {
    return 'Loading'
  }

  return (
    <div className='container'>
      <h3>{doc.title}</h3>
      <BlockContent blocks={doc.body} />
    </div>
  )
}
