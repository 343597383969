import { Link } from 'react-router-dom'
import styled from 'styled-components'

export default function AdminTools() {
  return (
    <Container>
      <Link to='/add-user'>Add CRM User</Link>
    </Container>
  )
}

const Container = styled.div`
  position: fixed;
  bottom: 5px;
  left: 5px;
  background-color: #000;
  z-index: 10;
  padding: 10px 15px;
  border-radius: 25px;

  a {
    color: #fff;
  }
`
