import DailyUsers from '../components/DailyUsers'

export default function Home() {
  return (
    <div className='container'>
      <h1 style={{ marginTop: '10px' }}>Home</h1>
      <div className='row'>
        <div className='col-6'>
          <div className='card'>
            <div className='card-body'>
              <h5 className='card-title'>Sign Ups Today</h5>
              <DailyUsers />
            </div>
          </div>
        </div>
        <div className='col-6'>
          <div className='card'>
            <div className='card-body'>
              <h5 className='card-title'>Supporters</h5>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
