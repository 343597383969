import { Link, useLocation } from 'react-router-dom'
import { useContext } from 'react'
import { UserContext } from '../context/userContext'
import { useHistory } from 'react-router-dom'
import * as Cookies from 'js-cookie'

export default function Nav() {
  const { state, dispatch } = useContext(UserContext)
  const history = useHistory()

  const location = useLocation()

  const logout = () => {
    dispatch({ type: 'logout' })
    Cookies.remove('session')
    history.push('/login')
  }
  return (
    <nav className='navbar navbar-dark bg-dark'>
      <Link to='/' className='navbar-brand'>
        CHD CRM
      </Link>
      <ul className='navbar-nav' style={{ flexDirection: 'row' }}>
        <li className='nav-item'>
          <Link
            to='/'
            className={`nav-link ${location.pathname === '/' && 'active'}`}
          >
            Home
          </Link>
        </li>
        <li className='nav-item'>
          <Link
            to='/docs'
            className={`nav-link ${location.pathname === '/docs' && 'active'}`}
          >
            Docs
          </Link>
        </li>
        <li className='nav-item'>
          <Link
            to='/import'
            className={`nav-link ${
              location.pathname === '/import' && 'active'
            }`}
          >
            Import
          </Link>
        </li>
        {state.user ? (
          // eslint-disable-next-line
          <a
            onClick={logout}
            className='nav-link active'
            style={{ fontWeight: '700', cursor: 'pointer' }}
          >
            {state.user.name}
          </a>
        ) : (
          <Link to='/login' className='nav-link'>
            Login
          </Link>
        )}
      </ul>
    </nav>
  )
}
