import { useState, useEffect } from 'react'
import UserCard from './UserCard'
import getUrl from '../hooks/getUrl'

export default function UserList() {
  const [users, setUsers] = useState([])

  useEffect(() => {
    async function getUsers() {
      let res = await fetch(`${getUrl()}/api/users`)
      res = await res.json()
      setUsers(res)
    }
    getUsers()
  }, [])
  return (
    <div>
      {users ? (
        <div className='row'>
          {users.map((user) => (
            <div className='col-6'>
              <UserCard name={user.name} email={user.email} role={user.role} />
            </div>
          ))}
        </div>
      ) : (
        <p>No Users Found</p>
      )}
    </div>
  )
}
