import { BrowserRouter as Router } from 'react-router-dom'
import Routes from './Routes'

import { UserContextProvider } from './context/userContext'

function App() {
  return (
    <Router>
      <UserContextProvider>
        <Routes />
      </UserContextProvider>
    </Router>
  )
}

export default App
